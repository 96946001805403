<template>
  <section class="hero">
    <div v-if="!isMobile()" class="columns is-gapless headers">
      <div class="column">
        <div class="header">
          <p class="title"></p>
        </div>
      </div>
      <div class="column is-two-thirds">
        <div class="header">
          <p class="title">HOTEL</p>
        </div>
      </div>
    </div>

    <div class="columns is-gapless">
      <div class="column filters">
        <div class="res-cont">
          <button
            class="button filterbtn is-light is-rounded"
            v-bind:class="{ actv: tomActv }"
            to="/prix"
            @click="changeFilter('tommorow')"
          >
            réservation pour demain
          </button>
          <button
            class="button filterbtn is-light is-rounded"
            v-bind:class="{ actv: incomActv }"
            to="/prix"
            @click="changeFilter('incomming')"
          >
            toutes réservations à venir
          </button>
          <button
            class="button filterbtn is-light is-rounded"
            v-bind:class="{ actv: pastActv }"
            to="/prix"
            @click="changeFilter('past')"
          >
            réservations passées
          </button>
        </div>
      </div>
      <div class="column is-two-thirds">
        <div class="res-cont">
          <div v-if="activFilter == 'past'">
            <transition-group name="fade">
              <div v-for="res of hotel" :key="res.uid" class="card">
                <div v-if="isPast[`${res.uid}`]">
                  <header class="card-header">
                    <div class="title-wrapper">
                      <p class="title is-3 is-uppercase">{{ res.name }}</p>
                      <div class="status-w info">
                        <p class="title status is-5">{{ res.uid }}</p>
                      </div>
                      <div v-if="res.ready" class="status-w paid">
                        <p class="title status is-5">chambre faite</p>
                      </div>
                      <div v-if="!res.ready" class="status-w pending">
                        <p class="title status is-5">chambre pas faite</p>
                      </div>
                      <a
                        class="open-elloha-link"
                        target="_blank"
                        :href="makeUrl(res.start)"
                        >Ouvrir dans Elloah</a
                      >
                    </div>
                  </header>
                  <div class="card-content">
                    <p class="title detail is-5">
                      Nom: <span>{{ res.name }}</span>
                    </p>
                    <p class="title detail is-5">
                      Chambre: <span>{{ res.room }}</span>
                    </p>
                    <p class="title detail is-5">
                      Date d'arrivée: <span>{{ res.start | formatDate }}</span>
                    </p>
                    <p class="title detail is-5">
                      Date de départ: <span>{{ res.end | formatDate }}</span>
                    </p>
                    <p class="title detail is-5">
                      Email: <span>{{ res.email }}</span>
                    </p>
                    <p class="title detail is-5">
                      Numéro de réservation: <span>{{ res.uid }}</span>
                    </p>
                    <p class="title detail is-5">
                      Prix: <span>{{ res.price }} €</span>
                    </p>
                    <div class="btnCont">
                      <button
                        v-if="!res.ready"
                        @click="updateReady(`${res.uid}`)"
                        class="button is-success is-light"
                      >
                        marquer la chambre comme faite
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
          <div v-if="activFilter == 'incomming'">
            <transition-group name="fade">
              <div v-for="res of hotel" :key="res.uid" class="card">
                <div v-if="!isPast[`${res.uid}`]">
                  <header class="card-header">
                    <div class="title-wrapper">
                      <p class="title is-3 is-uppercase">{{ res.name }}</p>
                      <div class="status-w info">
                        <p class="title status is-5">{{ res.room }}</p>
                      </div>
                      <div v-if="res.ready" class="status-w paid">
                        <p class="title status is-5">chambre faite</p>
                      </div>
                      <div v-if="!res.ready" class="status-w pending">
                        <p class="title status is-5">chambre pas faite</p>
                      </div>
                      <a
                        class="open-elloha-link"
                        target="_blank"
                        :href="makeUrl(res.start)"
                        >Ouvrir dans Elloah</a
                      >
                    </div>
                  </header>
                  <div class="card-content">
                    <p class="title detail is-5">
                      Nom: <span>{{ res.name }}</span>
                    </p>
                    <p class="title detail is-5">
                      Chambre: <span>{{ res.room }}</span>
                    </p>
                    <p class="title detail is-5">
                      Date d'arrivée: <span>{{ res.start | formatDate }}</span>
                    </p>
                    <p class="title detail is-5">
                      Date de départ: <span>{{ res.end | formatDate }}</span>
                    </p>
                    <p class="title detail is-5">
                      Email: <span>{{ res.email }}</span>
                    </p>
                    <p class="title detail is-5">
                      Numéro de réservation: <span>{{ res.uid }}</span>
                    </p>
                    <p class="title detail is-5">
                      Prix: <span>{{ res.price }} €</span>
                    </p>
                    <div class="btnCont">
                      <button
                        v-if="!res.ready"
                        @click="updateReady(`${res.uid}`)"
                        class="button is-success is-light"
                      >
                        marquer la chambre comme faite
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
          <div v-if="activFilter == 'tommorow'">
            <div v-if="isTommorow['trueCount'] < 1">
              <p class="title">Pas de réservation prévue pour demain</p>
            </div>
            <transition-group name="fade">
              <div v-for="res of hotel" :key="res.uid" class="card">
                <div v-if="isTommorow[`${res.uid}`]">
                  <header class="card-header">
                    <div class="title-wrapper">
                      <p class="title is-3 is-uppercase">{{ res.name }}</p>
                      <div class="status-w info">
                        <p class="title status is-5">{{ res.room }}</p>
                      </div>
                      <div v-if="res.ready" class="status-w paid">
                        <p class="title status is-5">chambre faite</p>
                      </div>
                      <div v-if="!res.ready" class="status-w pending">
                        <p class="title status is-5">chambre pas faite</p>
                      </div>
                      <a
                        class="open-elloha-link"
                        target="_blank"
                        :href="makeUrl(res.start)"
                        >Ouvrir dans Elloah</a
                      >
                    </div>
                  </header>
                  <div class="card-content">
                    <p class="title detail is-5">
                      Nom: <span>{{ res.name }}</span>
                    </p>
                    <p class="title detail is-5">
                      Chambre: <span>{{ res.room }}</span>
                    </p>
                    <p class="title detail is-5">
                      Date d'arrivée: <span>{{ res.start | formatDate }}</span>
                    </p>
                    <p class="title detail is-5">
                      Date de départ: <span>{{ res.end | formatDate }}</span>
                    </p>
                    <p class="title detail is-5">
                      Email: <span>{{ res.email }}</span>
                    </p>
                    <p class="title detail is-5">
                      Numéro de réservation: <span>{{ res.uid }}</span>
                    </p>
                    <p class="title detail is-5">
                      Prix: <span>{{ res.price }} €</span>
                    </p>
                    <div class="btnCont">
                      <button
                        v-if="!res.ready"
                        @click="updateReady(`${res.uid}`)"
                        class="button is-success is-light"
                      >
                        marquer la chambre comme faite
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <!-- <div class="column">
        <div class="res-cont"></div>
      </div> -->
    </div>
  </section>
</template>

<script>
import {db} from "../firebase";
import moment from "moment";

console.log(db);

export default {
  data() {
    return {
      hotel: [],
      secHotel: {},
      date: new Date(),
      activFilter: "tommorow",
      isPaste: {},
      incomActv: false,
      pastActv: false,
      tomActv: true,
    };
  },
  firestore() {
    return {
      hotel: db.collection("infos").doc("reservations"),
    };
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        console.log(value.seconds);
        return moment.unix(value.seconds).format("DD/MM/YYYY");
      }
    },
  },
  asyncComputed: {
    async isPast() {
      var isPasteObj = {};
      const hotelRef = db.collection("infos").doc("reservations");
      const doc = await hotelRef.get();
      if (!doc.exists) {
        console.log("No such document!");
      } else {
        console.log("Document data:", doc.data());
        // this.secHotel = { ...doc.data() };
        const data = doc.data();
        const keys = Object.keys(doc.data());
        for (const key of keys) {
          if (data[key].uid) {
            const start = data[key].start.toDate().getTime();
            const date = new Date().getTime();
            const isP = start < date;
            const uid = data[key].uid;
            console.log(`${uid} : ${isP}`);
            isPasteObj[uid] = isP;
          }
        }
      }
      console.log(isPasteObj);
      return await isPasteObj;
    },
    async isTommorow() {
      var isPasteObj = {};
      const hotelRef = db.collection("infos").doc("reservations");
      const doc = await hotelRef.get();
      if (!doc.exists) {
        console.log("No such document!");
      } else {
        console.log("Document data:", doc.data());
        const data = doc.data();
        const keys = Object.keys(doc.data());
        var trueCount = 0;
        for (const key of keys) {
          if (data[key].uid) {
            const start = data[key].start.toDate().getTime();
            const date = new Date().getTime();
            const isP = start >= date && start <= date + 86400000 * 2;
            const uid = data[key].uid;
            console.log(`${uid} : ${isP}`);
            if (isP) {
              trueCount++;
            }
            isPasteObj[uid] = isP;
          }
        }
      }
      isPasteObj["trueCount"] = trueCount;
      console.log("IS TOM");
      console.log(isPasteObj);
      return await isPasteObj;
    },
  },
  methods: {
    async updateReady(resID) {
      await db
        .collection("infos")
        .doc("reservations")
        // .update((`${resID}`["ready"] = true))
        .set(
          {
            [`${resID}`]: {
              ready: true,
            },
          },
          { merge: true }
        )
        .then(() => {
          console.log("SUCCES");
        });
    },
    changeFilter(state) {
      this.activFilter = state;
      if (state == "incomming") {
        this.tomActv = false;
        this.pastActv = false;
        this.incomActv = true;
      } else if (state == "past") {
        this.tomActv = false;
        this.pastActv = true;
        this.incomActv = false;
      } else if (state == "tommorow") {
        this.tomActv = true;
        this.pastActv = false;
        this.incomActv = false;
      }
    },
    makeUrl(start) {
      if (start) {
        console.log("MAKE URL");
        console.log(start.seconds);
        var arg1 = moment.unix(start.seconds).format("DD/MM/YYYY");
        var arg2 = `${arg1.charAt(0)}${
          parseInt(arg1.charAt(1)) + 1
        }/${arg1.charAt(3)}${arg1.charAt(4)}/${arg1.charAt(6)}${arg1.charAt(
          7
        )}${arg1.charAt(8)}${arg1.charAt(9)}`;
        return `https://app.elloha.com/reservations/reservations-list.aspx?ArrivalStartDate=${arg1}&ArrivalEndDate=${arg2}`;
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  name: "Hotel",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.columns.is-gapless.headers {
  margin-bottom: 0px;
}
.header {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 99;
  width: 100%;
  height: 60px;
  padding-top: 11px;
}
.res-cont {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 100%;
  height: calc(100vh - 150px);
  overflow: scroll;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
}
.card {
  margin-bottom: 10px;
  border-radius: 30px;
}
header.card-header {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.service {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 10px !important;
  line-height: 16px;
  text-align: left;
  padding-left: 20px;
}
.title-wrapper {
  width: 100%;
  padding: 12px;
}
.status-w {
  padding: 10px;
  margin: auto;
  margin-top: 10px;
  border-radius: 5px;
  display: block;
  width: 80%;
}
.status-w.pending {
  background-color: rgb(255, 217, 217);
}
.status-w.paid {
  background-color: rgb(226, 255, 217);
}

.status-w.info {
  background-color: rgb(217, 232, 255);
}

.pending .status {
  text-transform: uppercase;
  color: rgb(255, 0, 0);
}
.paid .status {
  text-transform: uppercase;
  color: green;
}

.info .status {
  text-transform: uppercase;
  color: rgb(0, 17, 255);
}

.card-header {
  text-align: center !important;
}
.card-header-title {
  text-align: center !important;
}

.title.detail {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  text-align: left;
  margin-top: 30px;
}

.detail.small-margin {
  margin-bottom: 10px;
  margin-top: 0px;
}

.detail span {
  font-weight: 600;
}
.card-footer .card-footer-item a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.25rem;
  color: green;
}
.button {
  width: 80%;
  border-radius: 17px;
  height: 50px;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
  transition: all 300ms;
}

.button.actv {
  background-color: rgb(226, 226, 226);
  box-shadow: 0px 10px 15px -2px rgba(0, 0, 0, 0.08) !important;
  transform: translate(0px, -5px) scale(1.1);
}
.open-elloha-link {
  display: block;
  padding: 15px;
  color: blue;
}
.open-elloha-link:hover {
  text-decoration: underline;
}
.btnCont .button {
  width: 100%;
  border-radius: 17px;
  height: 50px;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0px;
}
.btnCont {
  display: flex;
  gap: 12px;
}

@media only screen and (max-width: 768px) {
  .button {
    width: 90%;
    border-radius: 17px;
    height: 50px;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px !important;
    margin-top: 0px !important;
    transition: all 300ms;
  }
  .button.actv {
    background-color: rgb(226, 226, 226);
    box-shadow: 0px 10px 15px -2px rgba(0, 0, 0, 0.08) !important;
    transform: scale(1.01);
  }
  .res-cont {
    padding-left: 10px;
    padding-right: 10px;
  }
  .res-cont {
    border: none;
    height: calc(100vh - 260px);
  }
  .filters .res-cont {
    height: 190px;
  }
  .header {
    border: none !important;
  }
}
</style>
